<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  name: "index",

  metaInfo: {
    title: '鱼神脆皮烤鱼-广州金舌头餐饮品牌管理有限公司',
    meta: [
      {
        name: 'viewport',
        // width=device-width,height=device-height,
        content: 'width=device-width,initial-scale=1, maximum-scale=0.25,minimum-scale=0.1,user-scalable=no,shrink-to-fit=no,viewport-fit=cover,target-densitydpi=device-dpi'
      },
      {
        name: 'description',
        content: '鱼神,鱼神烤鱼,鱼神脆皮烤鱼,鱼神,强叔现切猪杂粥,强叔猪杂粥,广州金舌头餐饮品牌管理有限公司,惠州金舌头,惠东金舌头,聚惠厨房,广州烤鱼,广东烤鱼'
      },
      {
        name: 'keywords',
        content: '鱼神,鱼神烤鱼,鱼神脆皮烤鱼,烤鱼,鱼神,脆皮,强叔现切猪杂粥,强叔猪杂粥,金舌头,广州金舌头餐饮品牌管理有限公司,惠州金舌头,惠东金舌头,聚惠厨房,广州烤鱼,广东烤鱼'
      }
    ]
  }
}
</script>
<style lang="scss">

*{
  padding: 0;
  margin: 0; 
}

</style>
