import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import BaiduMap from 'vue-baidu-map'
import Meta from 'vue-meta'
import 'animate.css';
import 'view-design/dist/styles/iview.css';
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import LazyLoad from 'vue-lazyload'

Vue.use(LazyLoad,{
  preload:1,
  attempt:2
});

Vue.use(BaiduMap, {
  ak: 'tPZj359YgRvAuGoHhOFtSSX9IPV4SAkl'
})
Vue.config.productionTip = false
Vue.use(ViewUI);
Vue.use(Meta);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
